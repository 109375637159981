import React, { useEffect, useState } from "react"
import api from "../api"
import { Each } from "../common/Each"
import CourseCard from "../components/cards/CourseCard"
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout"
import typo from "../typography.module.css"
import styles from "./ShopCourses.module.css"
import { Helmet, HelmetProvider } from "react-helmet-async"
import Button from "../components/Button"
import AutoplayVerticalCarousel from "../components/AutoplayVerticalCarousel"
import LargeCourseCard from "../components/cards/LargeCourseCard"
import Skeleton from "../components/Skeleton"

const ShopCourses = () => {

    const [courses, setCourses] = useState(null)
    const [suggestedCourse, setSuggestedCourse] = useState(null)
    const [bestsellers, setBestsellers] = useState(null)
    const [coursesLoading, setCoursesLoading] = useState(false)
    const [bestsellersLoading, setBestsellersLoading] = useState(false)

    useEffect(() => {
        window.scrollTo({
            top: 0,
        })
    }, [])

    useEffect(() => {
        const getBestsellersCourses = async () => {
            setBestsellersLoading(true)
            try {
                let bestsellers = await api.get("/courses/0/bestsellers")
                setBestsellers(bestsellers)
            }
            catch (e) {
                console.error(e)
            }
            setBestsellersLoading(false)

        }


        const getSuggestedCourse = async () => {
            try {
                let suggestedCourse = await api.get("/courses/0/suggested")
                if (suggestedCourse) {
                    setSuggestedCourse(suggestedCourse)
                }
            }
            catch (e) {
                console.error(e)
            }
        }

        async function getCourses() {
            setCoursesLoading(true)
            try {
                let courses = await api.get("/courses")
                setCourses(courses)
            }
            catch (e) {
                console.error(e)
            }
            setCoursesLoading(false)
        }

        if (!courses) {
            getCourses()
        }
        if (!suggestedCourse) {
            getSuggestedCourse()
        }
        if (!bestsellers) {
            getBestsellersCourses()
        }
    }, [])

    return (
        <HeaderFooterLayout>
            <HelmetProvider>
                <Helmet>
                    <title>Corsi</title>
                </Helmet>
            </HelmetProvider>
            <div className={styles.container}>
                <div className={styles.section} style={{ backgroundColor: 'var(--background-color)', paddingTop: 0, paddingBottom: 0 }}>
                    <div className={styles.sectionInner}>
                        <div className={styles.banner}>
                            <div className={styles.bannerLeft}>
                                <div className={typo.title}>
                                    Scopri i nostri corsi
                                </div>
                                <div className={typo.body} style={{ paddingBottom: '2rem' }}>
                                    Stiamo creando insieme alle migliori aziende di ogni settore in Italia percorsi di formazione studiati appositamente per lavorare nel loro ambito e aiutarti ad entrare o crescere nel mondo del lavoro. Scopri quali percorsi sono già disponibili
                                </div>
                                <Button
                                    onClick={() => {
                                        let elem = document.querySelector('#suggested')
                                        if (elem) {
                                            elem.scrollIntoView({ behavior: 'smooth' })
                                        }
                                    }}
                                    accentColor={'var(--tertiary)'}
                                    style={{ padding: '0.7rem 2.25rem', fontSize: '1.125rem', fontWeight: 800 }}>
                                    SCOPRI I CORSI
                                </Button>
                            </div>
                            <div className={styles.bannerRight}>
                                {courses && courses.length > 0 &&
                                    <>
                                        <AutoplayVerticalCarousel
                                            elements={courses.map(c => c.thumbnail).slice(0, 4)}
                                            cardStyle={{ padding: 0, borderRadius: '1rem', overflow: 'hidden' }}
                                            imgStyle={{ objectFit: 'cover' }}
                                        />
                                        <AutoplayVerticalCarousel
                                            reverse
                                            elements={courses.map(c => c.thumbnail).slice(0, 4)}
                                            cardStyle={{ padding: 0, borderRadius: '1rem', overflow: 'hidden' }}
                                            imgStyle={{ objectFit: 'cover' }}
                                        />
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                {suggestedCourse &&
                    <div id="suggested" className={styles.section} style={{ background: 'linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(21, 224, 255, 0.2) 20%, rgba(255,255,255,0) 100%)' }}>
                        <div className={styles.sectionInner}>
                            <div className={styles.sectionTitle}>
                                <div style={{ width: '100%', flexDirection: 'column' }}>
                                    <div className={typo.subtitle}>Perfetto per te 🏅</div>
                                    <div className={typo.caption} style={{ fontSize: '0.75rem', opacity: 0.75 }}>Un corso scelto sulla base delle tue caratteristiche e del tuo percorso di carriera.</div>
                                </div>
                            </div>
                            <div className={styles.courses}>
                                <LargeCourseCard course={suggestedCourse} />
                            </div>
                        </div>
                    </div>
                }

                {bestsellersLoading &&
                    <div className={styles.section} style={{ background: 'linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(207, 85, 255, 0.12) 20%, rgba(255,255,255,0) 100%)' }}>
                        <div className={styles.sectionInner}>
                            <div className={styles.sectionTitle}>
                                <div style={{ width: '100%', flexDirection: 'column' }}>
                                    <Skeleton width={'300px'} height={30} borderRadius={'6px'} style={{ marginBottom: '.25rem' }} />
                                    <Skeleton width={'40%'} height={15} borderRadius={'4px'} />

                                </div>
                            </div>
                            <div className={styles.courses}>
                                <Each of={Array(3).fill(null)} render={(c, index) => {
                                    return (
                                        <div className={styles.courseCardWrapper}>
                                            <Skeleton height={320} width={'100%'} borderRadius={'1rem'} />
                                        </div>
                                    )
                                }} />
                            </div>
                        </div>
                    </div>
                }

                {bestsellers && bestsellers.length > 0 &&
                    <div className={styles.section} style={{ background: 'linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(207, 85, 255, 0.12) 20%, rgba(255,255,255,0) 100%)' }}>
                        <div className={styles.sectionInner}>
                            <div className={styles.sectionTitle}>
                                <div style={{ width: '100%', flexDirection: 'column' }}>
                                    <div className={typo.subtitle}>Bestsellers ✨</div>
                                    <div className={typo.caption} style={{ fontSize: '1rem', opacity: 0.75, marginTop: "12px" }}>I corsi di maggior successo, scelti e recensiti dalla community.</div>
                                </div>
                            </div>
                            <div className={styles.courses}>
                                {bestsellers &&
                                    <Each of={bestsellers} render={(c, index) => {
                                        return (
                                            <div className={styles.courseCardWrapper}>
                                                <CourseCard course={c} hover />
                                            </div>
                                        )
                                    }} />
                                }
                            </div>
                        </div>
                    </div>
                }

                {coursesLoading &&
                    <div className={styles.section} style={{ background: 'linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(207, 85, 255, 0.12) 20%, rgba(255,255,255,0) 100%)' }}>
                        <div className={styles.sectionInner}>
                            <div className={styles.sectionTitle}>
                                <div style={{ width: '100%', flexDirection: 'column' }}>
                                    <Skeleton width={'300px'} height={30} borderRadius={'6px'} style={{ marginBottom: '.25rem' }} />
                                    <Skeleton width={'40%'} height={15} borderRadius={'4px'} />

                                </div>
                            </div>
                            <div className={styles.courses}>
                                <Each of={Array(3).fill(null)} render={(c, index) => {
                                    return (
                                        <div className={styles.courseCardWrapper}>
                                            <Skeleton height={320} width={'100%'} borderRadius={'1rem'} />
                                        </div>
                                    )
                                }} />
                            </div>
                        </div>
                    </div>
                }

                {courses && courses.length > 0 &&

                    <div className={styles.section} style={{ background: 'linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(0,178,255,0.12) 20%, rgba(255,255,255,0) 100%)' }}>
                        <div className={styles.sectionInner}>
                            <div className={styles.sectionTitle}>
                                <div className={typo.subtitle}>Tutti i corsi</div>
                            </div>
                            <div className={styles.courses}>
                                {courses &&
                                    <Each of={courses} render={(c, index) => {
                                        return (
                                            <div className={styles.courseCardWrapper}>
                                                <CourseCard course={c} hover />
                                            </div>
                                        )
                                    }} />
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>

        </HeaderFooterLayout>
    )
}

export default ShopCourses
