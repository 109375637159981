import { useEffect, useRef, useState } from "react"
import styles from "./DailyControl.module.css"
import { ReactComponent as ChevronIcon } from "../../assets/images/icons/ic-chevron.svg"
import useClickOutside from "../../common/hooks/useClickOutside"
import Spinner from "../Spinner"
import useWindowDimensions from "../../common/hooks/useWindowDimensions"

const DailyControl = ({ icons, active, onClick = () => { }, open, onToggle = () => { }, hasOption, children, id, tooltip, loading, badgeNumber, level }) => {
    
    const { width } = useWindowDimensions()
    const [isActive, setIsActive] = useState(active)
    const [optionsOpen, setOptionsOpen] = useState(open)
    const optionsRef = useRef(null)
    const containerRef = useRef(null)
    const [isLoading, setIsLoading] = useState(false)
    const [audioLevelHeight, setAudioLevelHeight] = useState(0)

    const handleOnClick = () => {
        onClick()
    }

    const toggleOptions = (e) => {
        e.stopPropagation()
        onToggle()
        setOptionsOpen(!optionsOpen)
    }

    useEffect(() => {
        setIsActive(active)
    }, [active])

    useEffect(() => {
        setOptionsOpen(open)
    }, [open])

    useEffect(() => {
        setIsLoading(loading)
    }, [loading])

    const handleClickOutside = (e) => {
        if (!e.target.id.startsWith(id)) {
            setOptionsOpen(false)
        }
    }

    useEffect(() => {
        const l = ((100 / 9.5) * level) - (50 / 9.5)
        setAudioLevelHeight(l)
    }, [level])


    useClickOutside(optionsRef, handleClickOutside)

    const adjustOptionsPosition = () => {
        if (optionsRef && optionsRef.current && containerRef && containerRef.current) {
            let optionsRect = optionsRef.current.getBoundingClientRect()
            let containerRect = containerRef.current.getBoundingClientRect()
            if (optionsRect.x < 16 || optionsRect.x > window.clientWidth - 16) {
                optionsRef.current.style.left = `${(optionsRect.width / 2 - containerRect.x) + 16}px`
            }
            else {
                optionsRef.current.style.left = 0
            }
        }
    }

    useEffect(() => {
        adjustOptionsPosition()
    }, [optionsOpen, width])

    return (
        <div className={styles.container} ref={containerRef}>
            <button className={`${styles.iconButton} ${hasOption ? styles.hasOptions : ''}`} style={{ width: hasOption ? 'unset' : '40px' }} onClick={handleOnClick}>
                <div>
                    {!loading &&
                        <>
                            {isActive &&
                                icons.active
                            }
                            {!isActive &&
                                icons.normal
                            }
                        </>

                    }
                    {loading &&
                        <Spinner />
                    }
                    {audioLevelHeight > 0 &&
                        <div className={styles.audioLevel} style={{ height: `${((100 / 9.5) * level) - (50 / 9.5)}%` }}></div>
                    }
                </div>
            </button>
            {hasOption &&
                <button id={id} className={styles.optionsButton} onClick={toggleOptions}>
                    {hasOption &&
                        <ChevronIcon id={`${id}-chevron`} className={`${styles.chevron} ${optionsOpen ? styles.close : ''}`} />
                    }
                    {audioLevelHeight > 0 &&
                        <div className={styles.audioLevel} style={{ height: `${((100 / 9.5) * level) - (50 / 9.5)}%` }}></div>
                    }
                </button>
            }
            {optionsOpen &&
                <div className={styles.options} ref={optionsRef}>
                    {children}
                </div>
            }
            {tooltip &&
                <div className={styles.tooltip}>{tooltip}</div>
            }
            {badgeNumber > 0 &&
                <div className={styles.badge}>{badgeNumber}</div>
            }
        </div>

    )

}

export default DailyControl