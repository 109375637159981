import { useEffect, useState } from "react"
import { Each } from "../../common/Each"
import styles from "./Progress.module.css"
import usePrevious from "../../common/hooks/usePrevious"

const Progress = ({ nSteps, nCompleted }) => {

    const [classNames, setClassNames] = useState(Array(nSteps).fill(""))
    const [numbersClassNames, setNumbersClassNames] = useState(Array(nSteps).fill(""))
    const previous = usePrevious(nCompleted)

    useEffect(() => {
        const cls = Array(nSteps).fill("")
        for (let i = 0; i < nSteps; i++) {
            if (previous < nCompleted && i === nCompleted - 1) {
                cls[i] = styles.fill
            } else if (nCompleted < previous && i === nCompleted) {
                cls[i] = styles.empty
            } else if (
                (i < nCompleted && nCompleted < previous) ||
                (i < nCompleted && nCompleted > previous)
            ) {
                cls[i] = styles.completed
            }
        }
        setClassNames(cls)
        setNumbersClassNames(cls)
    }, [nCompleted])

    return (
        <div className={styles.container}>
            <Each of={Array(nSteps).fill(null)} render={(s, i) => {
                return (
                    <div className={styles.stepContainer} style={{ flex: i < nSteps - 1 ? 1 : 0 }}>
                        <div className={`${styles.number} ${numbersClassNames[i]}`}>
                            {i + 1}
                        </div>
                        <div className={`${styles.step} ${classNames[i]}`} />
                    </div>
                )
            }} />
        </div>
    )

}

export default Progress
