import { useDaily, useDailyError, useDailyEvent, useDevices, useMediaTrack, useParticipantProperty, useScreenShare } from "@daily-co/daily-react";
import { useEffect, useState } from "react";
import { ReactComponent as CameraOffIcon } from "../../assets/images/icons/ic-camera-off.svg";
import { ReactComponent as CameraIcon } from "../../assets/images/icons/ic-camera.svg";
import { ReactComponent as ChatIconOff } from "../../assets/images/icons/ic-chat.svg";
import { ReactComponent as CheckIcon } from "../../assets/images/icons/ic-check.svg";
import { ReactComponent as MicOffIcon } from "../../assets/images/icons/ic-mic-off.svg";
import { ReactComponent as MicIcon } from "../../assets/images/icons/ic-mic.svg";
import { ReactComponent as FullScreenIcon } from "../../assets/images/icons/ic-screen-maximize.svg";
import { ReactComponent as FullScreenOffIcon } from "../../assets/images/icons/ic-screen-minimize.svg";
import { ReactComponent as ScreenIcon } from "../../assets/images/icons/ic-screenshare.svg";
import { ReactComponent as UsersIcon } from "../../assets/images/icons/ic-users.svg";
import { ReactComponent as HandIcon } from "../../assets/images/icons/ic-hand.svg";
import { ReactComponent as SpeakerIcon } from "../../assets/images/icons/ic-speaker.svg"
import { ReactComponent as SpeakerIcon0 } from "../../assets/images/icons/ic-speaker0.svg"
import { Each } from "../../common/Each";
import useSpeechDetection from "../../common/hooks/useSpeechDetection";
import DailyControl from "./DailyControl";
import styles from "./DailyControls.module.css";
import DailySpinner from "./DailySpinner";
import AlertDialog from "../dialogs/AlertDialog"
import { DialogStatus } from "../../enums";
import { isMobile } from 'react-device-detect';

const DailyControls = ({ 
    localSessionId, 
    showParticipants, 
    onShowParticipantsChange,
    fullScreen, 
    onFullScreenChange, 
    chat, 
    onChatChange, 
    unread, 
    muted,
    onMutedChange  }) => {

    const call = useDaily()
    const localParticipant = useParticipantProperty(localSessionId, ['audio', 'video', 'user_name', 'userData', 'audioTrack'])
    const devices = useDevices()
    const audioTrack = useMediaTrack(localSessionId, 'audio')
    const { isSharingScreen, startScreenShare, stopScreenShare } = useScreenShare()
    const [loadingMic, setLoadingMic] = useState(null)
    const [loadingCam, setLoadingCam] = useState(null)
    const [loadingSpeaker, setLoadingSpeaker] = useState(null)
    const audioLevel = useSpeechDetection(audioTrack.track)

    const [handRaised, setHandRaised] = useState(null)
    const [accessState, setAccessState] = useState(null)
    const [permissionError, setPermissionError] = useState({ show: false, title: 'Errore', message: '' })
    const { nonFatalError } = useDailyError();

    const onAccessStateUpdate = async (ev) => {
        setAccessState(ev.access.level)
    }

    useEffect(() => {
        if (nonFatalError && nonFatalError.type) {
            if (nonFatalError.type === 'screen-share-error') {
                setPermissionError({ show: true, title: 'Permessi Condivisione Schermo', message: `Sembra che tu non abbia acconsentito alla condivisione schermo. Per favore consenti i permessi dalle impostazioni del tuo browser e aggiorna la pagina.` })
            }
        }
    }, [nonFatalError])

    useDailyEvent('access-state-updated', onAccessStateUpdate)

    useEffect(() => {
        setHandRaised(localParticipant?.[3]?.handRaised ?? false)
    }, [localParticipant])

    useEffect(() => {
        let accessState = call.accessState()
        setAccessState(accessState.access?.level ?? 'unknow')
    }, [call])

    async function checkPermissions(type) {
        try {
            if (!['camera', 'microphone'].includes(type)) {
                throw new Error("Wrong permission type.")
            }

            const permission = await navigator.permissions.query({ name: type });
            const isGranted = permission.state === 'granted';

            if (!isGranted) {
                await requestPermissions()
            }

        } catch (error) {
            console.error('Error checking permissions:', error);
        }
    }

    async function requestPermissions() {
        try {
            // Richiede l'accesso alla fotocamera e al microfono
            await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
            console.log('Permessi concessi per fotocamera e microfono');
        } catch (error) {
            console.error('Permessi negati o errore nel richiedere i permessi:', error);
            setPermissionError({ show: true, title: 'Permessi Fotocamera e Microfono', message: `Sembra che tu non abbia acconsentito l'accesso alla fotocamera e al microfono. Per favore consenti i permessi dalle impostazioni del tuo browser e aggiorna la pagina.` })
            throw error
        }
    }


    return (
        <div className={styles.container}>
            <DailyControl
                id="microphones"
                active={localParticipant[0]}
                icons={{
                    normal: <MicOffIcon className={styles.toolIcon} />,
                    active: <MicIcon className={styles.toolIcon} />
                }}
                onClick={() => {
                    checkPermissions('microphone')
                    call.setLocalAudio(!localParticipant[0])
                }}
                hasOption={devices.microphones.length > 1}
                level={localParticipant[0] ? audioLevel : 0}
            >
                <div className={styles.devices}>
                    <Each of={devices.microphones} render={(microphone) => (
                        <div className={styles.device} onClick={async (e) => {
                            e.stopPropagation()
                            setLoadingMic(microphone.device.deviceId)
                            await devices.setMicrophone(microphone.device.deviceId)
                            setLoadingMic(null)
                        }}>
                            <div className={styles.deviceSelected}>
                                {microphone.selected &&
                                    <CheckIcon style={{ width: '12px', height: '12px' }} />
                                }
                                {loadingMic === microphone.device.deviceId &&
                                    <DailySpinner />
                                }
                            </div>
                            <div className={styles.deviceLabel}>
                                {microphone.device.label}
                            </div>
                        </div>
                    )} />
                </div>

            </DailyControl>

            <DailyControl
                id="cameras"
                active={localParticipant[1]}
                icons={{
                    normal: <CameraOffIcon className={styles.toolIcon} />,
                    active: <CameraIcon className={styles.toolIcon} />
                }}
                onClick={() => {
                    checkPermissions('camera')
                    call.setLocalVideo(!localParticipant[1])
                }}
                hasOption={devices.cameras.length > 1}
            >
                <div className={styles.devices}>
                    <Each of={devices.cameras} render={(camera) => (
                        <div className={styles.device} onClick={async (e) => {
                            e.stopPropagation()
                            setLoadingCam(camera.device.deviceId)
                            await devices.setCamera(camera.device.deviceId)
                            setLoadingCam(null)
                            // setDevicesDropdown(null)
                        }}>
                            <div className={styles.deviceSelected}>
                                {camera.selected &&
                                    <CheckIcon style={{ width: '12px', height: '12px' }} />
                                }
                                {loadingCam === camera.device.deviceId &&
                                    <DailySpinner />
                                }
                            </div>
                            <div className={styles.deviceLabel}>
                                {camera.device.label}
                            </div>
                        </div>
                    )} />
                </div>
            </DailyControl>

            <DailyControl
                id="speakers"
                active={!muted}
                icons={{
                    normal: <SpeakerIcon0 className={styles.toolIcon} />,
                    active: <SpeakerIcon className={styles.toolIcon} style={{ color: 'var(--primary)' }} />
                }}
                onClick={() => {
                    // call.setLocalVideo(!localParticipant[1])
                    onMutedChange(!muted)
                }}
                hasOption={devices.speakers.length > 1}
            >
                <div className={styles.devices}>
                    <Each of={devices.speakers} render={(speaker) => (
                        <div className={styles.device} onClick={async (e) => {
                            e.stopPropagation()
                            setLoadingSpeaker(speaker.device.deviceId)
                            await Promise.all([
                                await devices.setSpeaker(speaker.device.deviceId),
                                new Promise(resolve => setTimeout(resolve, 500))
                            ]);

                            setLoadingSpeaker(null)
                        }}>
                            <div className={styles.deviceSelected}>
                                {speaker.selected && !loadingSpeaker &&
                                    <CheckIcon style={{ width: '12px', height: '12px' }} />
                                }
                                {loadingSpeaker === speaker.device.deviceId &&
                                    <DailySpinner />
                                }
                            </div>
                            <div className={styles.deviceLabel}>
                                {speaker.device.label}
                            </div>
                        </div>
                    )} />
                </div>
            </DailyControl>

            {accessState === 'full' &&
                <>
                    <DailyControl
                        active={showParticipants}
                        icons={{
                            normal: <UsersIcon className={`${styles.toolIcon}`} />,
                            active: <UsersIcon className={`${styles.toolIcon} ${styles.active}`} />
                        }}
                        onClick={() => {
                            onShowParticipantsChange()
                        }}
                        tooltip={'Utenti'}
                    />

                    <DailyControl
                        active={chat}
                        icons={{
                            normal: <ChatIconOff className={`${styles.toolIcon}`} />,
                            active: <ChatIconOff className={`${styles.toolIcon} ${styles.active}`} />
                        }}
                        onClick={() => {
                            onChatChange()
                        }}
                        tooltip="Chat"
                        badgeNumber={unread}
                    />

                    <DailyControl
                        active={handRaised}
                        icons={{
                            normal: <HandIcon className={`${styles.toolIcon}`} />,
                            active: <HandIcon className={`${styles.toolIcon} ${styles.active}`} />
                        }}
                        onClick={() => {
                            call.setUserData({ ...localParticipant[3], handRaised: !handRaised })
                            setHandRaised(prev => !prev)
                        }}
                        tooltip={handRaised ? "Abbassa la mano" : "Alza la mano"}
                    />

                    {!isMobile &&
                        <>
                            <DailyControl
                                active={isSharingScreen}
                                icons={{
                                    normal: <ScreenIcon className={`${styles.toolIcon}`} />,
                                    active: <ScreenIcon className={`${styles.toolIcon} ${styles.active}`} />
                                }}
                                onClick={() => {
                                    isSharingScreen ? stopScreenShare() : startScreenShare()
                                }}
                                tooltip="Condividi schermo"
                            />

                            <DailyControl
                                active={fullScreen}
                                icons={{
                                    normal: <FullScreenIcon className={`${styles.toolIcon}`} />,
                                    active: <FullScreenOffIcon className={`${styles.toolIcon} ${styles.active}`} />
                                }}
                                onClick={() => {
                                    onFullScreenChange()
                                }}
                                tooltip={fullScreen ? "Riduci" : "Espandi"}
                            />
                        </>
                    }
                </>
            }
            <AlertDialog
                open={permissionError.show}
                errorMessage={permissionError.title}
                text={permissionError.message}
                status={DialogStatus.Error}
                onClose={() => {
                    setPermissionError({ show: false, title: 'Errore', message: '' })
                }}
            />
        </div>

    )

}

export default DailyControls