import { useEffect, useRef, useState } from "react"
import styles from "./TextInput.module.css"
import { ReactComponent as SearchIcon } from "../assets/images/icons/ic-search.svg"
import { ReactComponent as EyeIcon } from "../assets/images/icons/ic-eye.svg"
import { ReactComponent as EyeHideIcon } from "../assets/images/icons/ic-eye-hide.svg"
import { filterAndSortArray, filterArray } from "../utils"

const TextInput = ({ type, value, placeholder, onKeyUp, autocomplete, suggestions, onSubmit, digitLimit, style = {} }) => {

    const [inputType, setInputType] = useState(type ?? 'text')
    const [icon, setIcon] = useState(<EyeIcon />)
    const [innerValue, setInnerValue] = useState(value ?? '')
    const [showSuggestions, setShowSuggestions] = useState(false)
    const [isFocused, setIsFocused] = useState(false)

    const ref = useRef(null);
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    });

    useEffect(() => {
        if (onKeyUp && typeof onKeyUp === 'function') {
            onKeyUp(innerValue)
        }
    }, [innerValue])

    const handleOnKeyUp = (event) => {
        setInnerValue(event.target.value)

        if (event.target.value.length > 0) {
            setShowSuggestions(true)
        }
        else {
            setShowSuggestions(false)
        }

        if (onKeyUp && typeof onKeyUp === 'function') {
            onKeyUp(event.target.value)
        }

    }

    const handleOnKeyUpNumeric = (event) => {
        const numericValue = event.target.value.replace(/[^0-9+]/g, '')
        if (digitLimit) {
            if (numericValue.length <= 2) {
                setInnerValue(numericValue)
            }
        }
        else {
            setInnerValue(numericValue)
        }
        if (onKeyUp && typeof onKeyUp === 'function') {
            onKeyUp(event.target.value)
        }
    }

    const showPassword = () => {
        if (inputType === 'password') {
            setInputType('text')
            setIcon(<EyeHideIcon />)
        }
        else {
            setInputType('password')
            setIcon(<EyeIcon />)
        }
    }

    function matchSuggestion() {
        return filterArray(suggestions, innerValue).length > 0
    }


    const handleClickOutside = (e) => {
        if (ref.current && !ref.current.contains(e.target)) {
            setShowSuggestions(false)
        }
    }

    const handleKeyDown = (event) => {
        if (onSubmit && typeof onSubmit === 'function') {
            if (event.key === 'Enter' && !event.shiftKey) {
                event.preventDefault()
                onSubmit(event.target.value)
            }
        }
    };

    return (
        <>
            {type === 'password' &&
                <div className={styles.container}>
                    <input value={innerValue ?? ''} type={inputType} className={[styles.roundedInput, styles.passwordInput].join((' '))} placeholder={placeholder} onChange={handleOnKeyUp} onKeyUp={handleOnKeyUp} autoComplete={autocomplete}></input>
                    <div className={styles.showPassword} onClick={showPassword}>
                        {icon}
                    </div>
                </div>
            }
            {type === 'text' &&
                <div className={styles.container}>
                    <input
                        value={innerValue}
                        type={inputType}
                        className={styles.roundedInput}
                        placeholder={placeholder}
                        onChange={handleOnKeyUp}
                        onKeyUp={handleOnKeyUp}
                        autoComplete={autocomplete}
                        onFocus={() => { setIsFocused(true) }}
                        onBlur={() => { setIsFocused(false) }}
                        style={{ zIndex: isFocused ? 100 : 0 }}></input>
                    {showSuggestions && suggestions && matchSuggestion() &&
                        <div className={styles.suggestions} ref={ref}>
                            {filterAndSortArray(suggestions, innerValue)
                                .map(sugg => {
                                    return (
                                        <button key={sugg} className={styles.suggestion}
                                            onClick={() => { setInnerValue(sugg); setShowSuggestions(false) }}>{sugg}</button>
                                    )
                                })}
                        </div>
                    }
                </div>
            }
            {type === 'search' &&
                <div className={styles.container}>
                    <input value={innerValue ?? ''} type={inputType} className={styles.searchInput} placeholder={placeholder} onChange={handleOnKeyUp} onKeyUp={handleOnKeyUp} autoComplete={autocomplete} onKeyDown={handleKeyDown}></input>
                    <SearchIcon className={styles.searchIcon} />
                </div>
            }
            {type === 'number' &&
                <input value={innerValue ?? '0'} inputMode="numeric" type='text' className={styles.roundedInput} placeholder={placeholder} onChange={handleOnKeyUpNumeric} onKeyUp={handleOnKeyUpNumeric} autoComplete={autocomplete} style={style} />
            }

        </>
    )

}

export default TextInput
