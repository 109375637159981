import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from 'react-router-dom';
import api from "../../api";
import MainContext from "../../common/MainContext";
import useWindowDimensions from "../../common/hooks/useWindowDimensions";
import { ReactComponent as BookIconActive } from "../../assets/images/icons/ic-book-active.svg";
import { ReactComponent as BookIcon } from "../../assets/images/icons/ic-book-inactive.svg";
import { ReactComponent as CareersIconActive } from "../../assets/images/icons/ic-careers-active.svg";
import { ReactComponent as CareersIcon } from "../../assets/images/icons/ic-careers.svg";
import { ReactComponent as DashboardIconActive } from "../../assets/images/icons/ic-dashboard-active.svg";
import { ReactComponent as DashboardIcon } from "../../assets/images/icons/ic-dashboard-inactive.svg";
import { ReactComponent as MinimizeIcon } from "../../assets/images/icons/ic-minimize.svg";
import { ReactComponent as PathIconActive } from "../../assets/images/icons/ic-path-active.svg";
import { ReactComponent as PathIcon } from "../../assets/images/icons/ic-path.svg";
import { ReactComponent as ChatIcon } from "../../assets/images/icons/ic-chat.svg";
import { ReactComponent as ChatIconActive } from "../../assets/images/icons/ic-chat-active.svg";
import { ReactComponent as LogoMini } from "../../assets/images/velv-minimized.svg";
import { ReactComponent as Logo } from "../../assets/images/velv-black.svg";
import { ReactComponent as CartIcon } from "../../assets/images/icons/ic-cart-stroked.svg"
import { ReactComponent as CartFillIcon } from "../../assets/images/icons/ic-cart.svg"
import { ReactComponent as BellIcon } from "../../assets/images/icons/ic-notification.svg"
import { ReactComponent as BellFillIcon } from "../../assets/images/icons/ic-notification-active.svg";
import { ReactComponent as PersonalityIcon } from "../../assets/images/icons/ic-personality.svg"
import { ReactComponent as PersonalityFillIcon } from "../../assets/images/icons/ic-personality-active.svg"
import UserPlaceholder from "../../assets/images/placeholders/user-placeholder.png"
import Hamburger from "./Hamburger";
import styles from "./SideNav.module.css";
import TextInput from "../TextInput";

const SideNav = ({ onChange = () => {} }) => {

    const { t } = useTranslation()
    const { width } = useWindowDimensions()
    const location = useLocation()
    const context = useContext(MainContext)
    const [query, setQuery] = useState('')

    const navigate = useNavigate()

    const [isOpen, setIsOpen] = useState(width > 767)

    useEffect(() => {
        return () => {
            document.body.style.overflow = '';
        }
    }, [])

    useEffect(() => {
        setIsOpen(width > 1120)
    }, [width])

    useEffect(() => {
        if (context.scrollRef?.current) {
            context.scrollRef?.current.scrollIntoView()
        }
    }, [location])

    useEffect(() => {

        document.dispatchEvent(new CustomEvent(isOpen ? 'sidenav-open' : 'sidenav-closed'));

        if (isOpen && width <= 768) {
            document.body.style.overflow = 'hidden';
        }
        else {
            document.body.style.overflow = '';
        }
    }, [isOpen, width])

    useEffect(() => {
        async function getUser() {
            try {
                let user = await api.get("/user")
                if (user) {
                    context.setUser(user)
                }
            }
            catch (error) {
                if (error.code === 2) {
                    context.setUser({ id: 0, isAnonymous: true })
                }
            }
        }
        if (!context.user) {
            getUser()
        }
    }, [context])

    function toggleOpen() {

        setIsOpen(!isOpen)

        if (onChange) {
            onChange(!isOpen)
        }
    }

    function closeSideNav() {
        if (width <= 768) {
            setIsOpen(false)
        }
    }

    function navigateTo(page) {
        navigate(page)
    }

    function getIcon(page) {
        if (page === '') {
            if (location.pathname === '/') {
                return <DashboardIconActive style={{ width: '20px', height: '20px' }}></DashboardIconActive>
            }
            else {
                return <DashboardIcon style={{ width: '20px', height: '20px' }}></DashboardIcon>
            }
        }

        if (location.pathname.split('/')[1].includes(`${page}`)) {
            switch (page) {
                case '':
                    return <DashboardIconActive style={{ width: '20px', height: '20px' }}></DashboardIconActive>
                case 'courses':
                    return <BookIconActive style={{ width: '20px', height: '20px' }}></BookIconActive>
                case 'careers':
                    return <CareersIconActive style={{ width: '20px', height: '20px' }}></CareersIconActive>
                case 'my-path':
                    return <PathIconActive style={{ width: '20px', height: '20px' }}></PathIconActive>
                case 'messages':
                    return <ChatIconActive style={{ width: '20px', height: '20px' }}></ChatIconActive>
                case 'personalities':
                    return <PersonalityFillIcon style={{ width: '20px', height: '20px' }}></PersonalityFillIcon>
                default: return
            }
        }
        else {
            switch (page) {
                case '':
                    return <DashboardIcon style={{ width: '20px', height: '20px' }}></DashboardIcon>
                case 'courses':
                    return <BookIcon style={{ width: '20px', height: '20px' }}></BookIcon>
                case 'careers':
                    return <CareersIcon style={{ width: '20px', height: '20px' }}></CareersIcon>
                case 'my-path':
                    return <PathIcon style={{ width: '20px', height: '20px' }}></PathIcon>
                case 'messages':
                    return <ChatIcon style={{ width: '20px', height: '20px' }}></ChatIcon>
                case 'personalities':
                    return <PersonalityIcon style={{ width: '20px', height: '20px' }}></PersonalityIcon>
                default: return
            }
        }
    }


    const checkOnboarding = async (pathname, onboarding) => {
        if (!onboarding.completed) {
            for (let step of onboarding.steps) {
                if (step.type === 'page') {
                    for (let action of step.actions) {
                        if (!step.actions.map(a => a.actual).includes(pathname) && !action.completed && !(action.actual === pathname) && action.match === 'wildcard' && pathname.includes(action.page)) {
                            action.completed = true
                            action.actual = pathname
                        }
                    }
                }
                let step_actions_values = step.actions.map(a => a.completed)
                if (!step_actions_values.includes(false)) {
                    step.completed = true
                }
            }
        }

        context.setOnboarding({ ...onboarding })
    }

    useEffect(() => {
        let pathname = location.pathname

        if (!context.onboarding) {
            const getOnboarding = async () => {
                try {
                    let onboarding = await api.get("/user/onboarding")
                    context.setOnboarding({ ...onboarding })
                    checkOnboarding(pathname, onboarding)
                }
                catch (e) {
                    console.error(e)
                }
            }

            getOnboarding()
        }
        else {
            checkOnboarding(pathname, context.onboarding)
        }
    }, [location])

    return (
        <>
            {context.user &&
                <>
                    <div className={styles.sidenav + ' ' + (isOpen ? styles.open : styles.close)} id="sidenav">
                        <div className={styles.header}>

                            {width > 768 && !isOpen &&
                                <LogoMini className={styles.logoMini} onClick={() => { navigate('/') }}></LogoMini>
                            }

                            {(isOpen || width <= 768) &&
                                <>
                                    <Logo className={styles.logo} onClick={() => { navigate('/') }}></Logo>
                                    <div className={styles.spacer}></div>
                                </>
                            }

                            {width <= 768 &&
                                <>
                                    <div className={styles.iconButtons}>
                                        <button className={styles.iconButton} onClick={() => {
                                            closeSideNav()
                                            navigateTo("/notifications")
                                        }}>
                                            {context.notifications?.metadata.unread === 0 &&
                                                <BellIcon />
                                            }
                                            {context.notifications?.metadata.unread > 0 &&
                                                <>
                                                    <BellFillIcon />
                                                    <div className={styles.badge}>
                                                        {context.notifications.metadata.unread}
                                                    </div>
                                                </>
                                            }
                                        </button>
                                        <button className={styles.iconButton} onClick={() => {
                                            closeSideNav()
                                            navigateTo("/cart")
                                        }}>
                                            {context.cart?.products?.length === 0 &&
                                                <CartIcon></CartIcon>
                                            }
                                            {context.cart?.products?.length > 0 &&
                                                <>
                                                    <CartFillIcon></CartFillIcon>
                                                    <div className={styles.badge}>
                                                        {context.cart.products.length}
                                                    </div>
                                                </>
                                            }
                                        </button>
                                    </div>
                                    <div className={styles.hamburger}>
                                        <Hamburger isOpen={isOpen} onChange={(event) => { setIsOpen(event.target.checked) }}></Hamburger>
                                    </div>
                                </>
                            }

                            {width > 768 &&
                                <button className={styles.closeButton} onClick={toggleOpen} aria-label="close-sidebar">
                                    <MinimizeIcon></MinimizeIcon>
                                </button>
                            }
                        </div>
                        <div className={`${styles.content}`}>
                            {width <= 768 && !location.pathname.includes('search') &&
                                <div className={styles.search}>
                                    <TextInput value={query} onKeyUp={(value) => { setQuery(value) }} placeholder={t('header.search')} type="search" onSubmit={() => {
                                        toggleOpen()
                                        setQuery('')
                                        navigate(`/search?q=${query}`)
                                    }}></TextInput>
                                </div>
                            }
                            <button className={[styles.navLink, location.pathname === '/' ? styles.active : ''].join(' ')}
                                onClick={() => {
                                    closeSideNav()
                                    navigateTo('/')
                                }}>
                                {getIcon('')}
                                {isOpen &&
                                    <>
                                        Dashboard
                                    </>
                                }
                            </button>
                            <button className={[styles.navLink, location.pathname.split("/")[1].includes('courses') ? styles.active : ''].join(' ')}
                                onClick={() => {
                                    closeSideNav()
                                    navigateTo('/courses')
                                }}>
                                {getIcon('courses')}
                                {isOpen &&
                                    <>
                                        {t('sidenav.courses')}
                                    </>
                                }
                            </button>
                            <button className={[styles.navLink, location.pathname.split("/")[1].includes('careers') ? styles.active : ''].join(' ')}
                                onClick={() => {
                                    closeSideNav()
                                    navigateTo('/careers')
                                }}>
                                {getIcon('careers')}
                                {isOpen &&
                                    <>
                                        {t('sidenav.careers')}
                                    </>
                                }
                            </button>
                            <button className={[styles.navLink, location.pathname.split("/")[1].includes('my-path') ? styles.active : ''].join(' ')}
                                onClick={() => {
                                    closeSideNav()
                                    navigateTo('/my-path')
                                }}>
                                {getIcon('my-path')}
                                {isOpen &&
                                    <>
                                        {t('sidenav.myPath')}
                                    </>
                                }
                            </button>
                            {/* <button className={[styles.navLink, location.pathname.split("/")[1].includes('personalities') ? styles.active : ''].join(' ')}
                                onClick={() => {
                                    closeSideNav()
                                    navigateTo('/personalities')
                                }}>
                                {getIcon('personalities')}
                                {isOpen &&
                                    <>
                                        {t('sidenav.personality')}
                                    </>
                                }
                            </button> */}

                            <div className={styles.spacer}></div>

                            <button className={[styles.bottomNavLink, location.pathname.split("/")[1].includes('messages') ? styles.active : ''].join(' ')}
                                onClick={() => {
                                    closeSideNav()
                                    navigateTo('/messages')
                                }}>
                                {getIcon('messages', context.un)}
                                {context.unreadMessages > 0 &&
                                    <div className={styles.iconBadge}>{context.unreadMessages}</div>
                                }
                                {isOpen &&
                                    <>
                                        {t('sidenav.messages')}
                                    </>
                                }
                            </button>
                            <button className={styles.userButton} onClick={() => {
                                closeSideNav()
                                navigateTo("/profile")
                            }}>
                                <div className={styles.userContainer}>
                                    <img src={context.user.picture ?? UserPlaceholder} alt="avatar" className={styles.userAvatar} />
                                    <div className={styles.userName}>{`${context.user.name ?? ''} ${context.user.surname ?? ''}`}</div>
                                </div>
                            </button>
                        </div>
                    </div>
                </>
            }
        </>

    )

}

export default SideNav
