import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout"
import styles from "./Personalities.module.css"
import api from "../api"
import { useCallback, useContext, useEffect, useRef, useState } from "react"
import { shuffleArray, sortByNotNull } from "../utils"
import questionsJson from '../assets/questions.json'
import characteristic from '../assets/characteristic2.json'
import BottomBar from "../components/BottomBar"
import Question from "../components/Question"
import RadarChart from "../components/RadarChart"
import typo from "../typography.module.css"
import Loader from "../components/Loader"
import { HelmetProvider, Helmet } from "react-helmet-async"
import Button from "../components/Button"
import MainContext from "../common/MainContext"
import AlertDialog from "../components/dialogs/AlertDialog"
import EI from "../assets/images/illustrations/3D/EI.png"
import SN from "../assets/images/illustrations/3D/SN.png"
import JP from "../assets/images/illustrations/3D/JP.png"
import TF from "../assets/images/illustrations/3D/TF.png"
import { Each } from "../common/Each"
import { ReactComponent as ArrowIcon } from "../assets/images/icons/ic-arrow.svg"
import Card from "../components/cards/Card"
import Flash3D from "../assets/images/illustrations/3D/flash.png"
import useWindowDimensions from "../common/hooks/useWindowDimensions"
import { Splide, SplideSlide } from '@splidejs/react-splide';
import Pro3D from "../assets/images/illustrations/3D/pros.png"
import Cons3D from "../assets/images/illustrations/3D/cons.png"

const Personalities = () => {

    const { width } = useWindowDimensions()
    const context = useContext(MainContext)
    const [index, setIndex] = useState(-1)

    const [continueDisabled, setContinueDisabled] = useState(true)
    const [result, setResult] = useState(null)
    const [loadingResult, setLoadingResult] = useState(true)

    const [char, setChar] = useState(null)

    const [radarData, setRadarData] = useState(null)
    const [questions, setQuestions] = useState(shuffleArray(questionsJson))
    const [continueLabel, setContinueLabel] = useState('INIZIA')
    const [alert, setAlert] = useState({ open: false, title: '', text: '' })

    const cards = [
        {
            axis1: "Estroversione (E)",
            axis2: "Introversione (I)",
            description: "Come una persona ricarica la propria energia, preferendo attività solitarie o sociali",
            gradientStart: "#FCDDA4",
            gradientEnd: "#FFEFD1 ",
            image: EI
        },
        {
            axis1: "Sensazione (S)",
            axis2: "Intuizione (N)",
            description: "Come si percepisce il mondo, attraverso dettagli pratici o intuizioni astratte",
            gradientStart: "#CD95DA",
            gradientEnd: "#F6CEFF ",
            image: SN
        },
        {
            axis1: "Pensiero (T)",
            axis2: "Sentimento (F)",
            description: "Come si prendono decisioni, con logica o valori personali",
            gradientStart: "#96E8B1",
            gradientEnd: "#C0FDD4 ",
            image: TF
        },
        {
            axis1: "Giudizio (J)",
            axis2: "Percezione (P)",
            description: "Come si approccia la vita, pianificando o rimanendo flessibili",
            gradientStart: "#FCA4A4",
            gradientEnd: "#FFCACA ",
            image: JP
        }

    ]

    const radarOptions = {
        grid: {
            paddingVertical: 60,
            paddingHorizontal: 60,
            levels: 3,
            sideLength: 150,
            borderRadius: 10,
            fill: '#2A2A2A04',
            stroke: '#2A2A2A10',
            strokeWidth: 2
        },
        labels: {
            dots: true,
            dotColors: ['#FAB666', '#60D265', '#4CB4FF', '#FA6676', '#B966FA'],
            dotsRadius: 5,
            dotsDelta: 1.15,
            font: {
                family: 'Work Sans',
                size: '12px',
                weight: '800'
            },
            padding: 12
        }
    }

    const axisLabel = [
        {
            values: ['Estroversione', 'Introversione'],
            symbol: 'E/I'
        },
        {
            values: ['Sensazione', 'Intuizione'],
            symbol: 'S/N'
        },
        {
            values: ['Pensiero', 'Sentimento'],
            symbol: 'T/F'
        },
        {
            values: ['Giudizio', 'Percezione'],
            symbol: 'J/P'
        }
    ]

    const restart = () => {
        setResult(null)
        localStorage.removeItem("result")
        setQuestions(shuffleArray(questionsJson))
        localStorage.removeItem("questions")
        setIndex(-1)
    }

    useEffect(() => {

        const getPersonality = async () => {
            setLoadingResult(true)
            try {
                let personality = await api.get("/personalities")
                if (personality) {
                    setResult(personality.results)

                    var onboarding = { ...context.onboarding }
                    for (let step of onboarding.steps) {
                        if (step.type === 'personalities') {
                            step.actions[0].completed = true
                            step.completed = true
                        }
                    }

                    context.setOnboarding({ ...onboarding })
                }
            }
            catch (e) {
                console.error(e)
            }
            setLoadingResult(false)
        }

        let q = localStorage.getItem("questions")
        let isRetrying = false

        if (q) {
            const parsedQ = JSON.parse(q);
            setQuestions(sortByNotNull(parsedQ));
            isRetrying = parsedQ.find(q => q.value)

            if (!parsedQ.find(q => q.value)) {
                setIndex(-1)
            }
            else {
                for (let i = 99; i >= 0; i--) {
                    if (!parsedQ[i].value) {
                        setIndex(i)
                    }
                    if (i === 99 && parsedQ[i].value) {
                        setIndex(99)
                    }
                }
            }

        }

        if (isRetrying) {
            setLoadingResult(false)
        }

        if (!result && !isRetrying) {
            getPersonality()
        }
    }, [])

    useEffect(() => {
        const handleEnter = (e) => {
            if (e.key === "Enter" && !continueDisabled) {
                onContinue()
            }
        }
        window.addEventListener("keydown", handleEnter)
        return () => {
            window.removeEventListener("keydown", handleEnter)
        }
    }, [continueDisabled])

    useEffect(() => {
        if (result) {
            setContinueDisabled(false)
            setContinueLabel('RIPETI IL TEST')
        }
        else {
            if (index === -1) {
                setContinueDisabled(false)
                setContinueLabel('INIZIA')
            }
            if (index > -1 && !questions[index].value) {
                setContinueDisabled(true)
                setContinueLabel('AVANTI')
            }
            if (index > -1 && questions[index].value) {
                setContinueDisabled(false)
                setContinueLabel('AVANTI')
            }
        }
    }, [questions, index, result])

    useEffect(() => {
        if (result) {

            var radarValues = []
            var radarLabels = []
            var metadata = []
            var colors = ['#FAB666', '#60D265', '#4CB4FF', '#FA6676', '#B966FA']

            for (let key of Object.keys(result.axis_scores)) {
                radarValues.push(result.axis_scores[key])
                radarLabels.push(key)
                let index = radarLabels.length - 1;
                metadata.push({
                    key: key,
                    color: colors[index % colors.length]
                });
            }

            const radarDatasets = [
                {
                    values: radarValues,
                    labels: radarValues,
                    metadata: metadata,
                    colors: colors,
                    tension: 1,
                    stroke: 'rgb(47, 198, 160)',
                    strokeWidth: 2,
                    fill: 'rgba(47, 198, 160, 10%)'
                }
            ]

            setRadarData(radarDatasets)

            let char = characteristic.find(c => c.code === result.personality_code)
            setChar(char)
        }
    }, [result])

    useEffect(() => {
        localStorage.setItem('questions', JSON.stringify(questions));
    }, [questions])

    const calculatePersonality = async () => {
        try {
            let result = await api.post("/personalities",
                {
                    test: questions
                })
            setResult(result)
            localStorage.setItem("result", JSON.stringify(result))
            localStorage.removeItem("questions")

            var onboarding = { ...context.onboarding }
            for (let step of onboarding.steps) {
                if (step.type === 'personalities') {
                    step.actions[0].completed = true
                    step.completed = true
                }
            }

            context.setOnboarding({ ...onboarding })
        }
        catch (e) {
            console.error(e)
        }
    }

    const onSelect = (id, value) => {
        // Set value to question
        setQuestions((prev) => {
            prev.map((q) => {
                if (q.id === id) {
                    q.value = value
                }
                return q
            })
            return [...prev]
        })
    }

    const onContinue = () => {
        if (index < 99) {
            setIndex(index + 1)
        }
        if (index === 99 && !result) {
            if (context.user.isAnonymous) {
                context.setLoginDialog(true)
            }
            else {
                calculatePersonality()
            }
        }
        if (result) {
            setAlert({
                open: true,
                title: "Riavviare il test?",
                text: "Tutte le risposte correnti saranno eliminate.",
                actions: [
                    {
                        label: 'RIAVVIA',
                        onClick: () => {
                            restart()
                            setAlert((prev) => {
                                prev.open = false
                                return { ...prev }
                            })
                        }
                    }
                ]
            })
        }
    }

    return (
        <HeaderFooterLayout hideFooter={index !== -1 || result}>
            <HelmetProvider>
                <Helmet>
                    <title>La mia personalità</title>
                </Helmet>
            </HelmetProvider>
            <div className={styles.container}>
                <div className={styles.content}>
                    {loadingResult &&
                        <div className={styles.loader}>
                            <Loader />
                        </div>
                    }
                    {!result && !loadingResult &&
                        <>
                            {index === -1 &&
                                <div className={styles.how}>
                                    <div className={styles.padded}>
                                        <div className={typo.title}>
                                            Come funziona il test?
                                        </div>
                                        <div className={styles.testDescription}>
                                            Il nostro test psicometrico è basato sul MBTI (Myers-Briggs Type Indicator), un questionario psicologico che si basa sulla teoria dei tipi psicologici di Carl Jung. Attraverso <b>100 domande</b>, il test esplora quattro dimensioni principali della personalità:
                                        </div>
                                    </div>

                                    <div className={styles.testCards}>
                                        {width > 540 &&
                                            <Each of={cards} render={(card) => {
                                                return (
                                                    <div className={styles.testCard} style={{ background: `linear-gradient(to bottom, ${card.gradientStart} 0%, ${card.gradientEnd} 100%)` }}>
                                                        <div className={styles.cardImageWrapper}>
                                                            <img src={card.image} alt={card.name} className={styles.cardImage} />
                                                        </div>
                                                        <div className={typo.subtitle} style={{ whiteSpace: 'nowrap' }}>{card.axis1}<br></br>{card.axis2}</div>
                                                        <div>
                                                            {card.description}
                                                        </div>
                                                    </div>
                                                )
                                            }} />
                                        }
                                        {width <= 540 &&
                                            <Splide
                                                id={styles.cardsCarousel}
                                                options={
                                                    {
                                                        perPage: 1,
                                                        gap: '1rem',
                                                        drag: true,
                                                        padding: '1rem'
                                                    }
                                                }
                                            >
                                                <Each of={cards} render={(card) => {
                                                    return (
                                                        <SplideSlide className={styles.cardSlide}>
                                                            <div className={styles.testCard} style={{ background: `linear-gradient(to bottom, ${card.gradientStart} 0%, ${card.gradientEnd} 100%)` }}>
                                                                <div className={styles.cardImageWrapper}>
                                                                    <img src={card.image} alt={card.name} className={styles.cardImage} />
                                                                </div>
                                                                <div className={typo.subtitle}>{card.axis1}<br></br>{card.axis2}</div>
                                                                <div>
                                                                    {card.description}
                                                                </div>
                                                            </div>
                                                        </SplideSlide>
                                                    )
                                                }} />
                                            </Splide>
                                        }
                                    </div>

                                    <div className={styles.infos}>
                                        <div className={styles.infoCard}>
                                            <div className={typo.subtitle} style={{ whiteSpace: 'normal' }}>
                                                Risposte su scala da 1 a 5
                                            </div>
                                            1 significa “totalmente in disaccordo” e 5 significa “totalmente d’accordo”
                                        </div>
                                        <div className={styles.infoCard}>
                                            <div className={typo.subtitle} style={{ whiteSpace: 'normal' }}>
                                                Test validato con IA
                                            </div>
                                            Il nostro test viene validato dall’intelligen- za artificiale per migliorare l’esperienza
                                        </div>
                                        <div className={styles.infoCard}>
                                            <div className={typo.subtitle} style={{ whiteSpace: 'normal' }}>
                                                Scopri la tua personalità
                                            </div>
                                            Il test offre un profilo unico che aiuta a comprendere sé stessi e le proprie interazioni sociali.
                                        </div>
                                    </div>

                                    <div className={styles.buttonContainer}>
                                        <Button
                                            fullWidth={width <= 540}
                                            style={{ padding: '1rem 5rem', fontSize: '1.125rem' }}
                                            onClick={onContinue}
                                        >
                                            INIZIA <ArrowIcon className={styles.arrowIcon} />
                                        </Button>
                                    </div>
                                </div>
                            }
                            {index > -1 &&
                                <>
                                    <div className={styles.topBar}>
                                        <div className={styles.progress}>
                                            {index + 1}/{questions.length}
                                        </div>
                                        <Button appearance="text"
                                            additionalClass={styles.restartButton}
                                            onClick={() => {
                                                setAlert({
                                                    open: true,
                                                    title: "Riavviare il test?",
                                                    text: "Tutte le risposte correnti saranno eliminate.",
                                                    actions: [
                                                        {
                                                            label: 'RIAVVIA',
                                                            onClick: () => {
                                                                restart()
                                                                setAlert((prev) => {
                                                                    prev.open = false
                                                                    return { ...prev }
                                                                })
                                                            }
                                                        }
                                                    ]
                                                })
                                            }}>
                                            Riavvia il test
                                        </Button>
                                    </div>
                                    <div className={styles.questions}>
                                        <Question index={index} total={questions.length} question={questions[index]} onSelect={onSelect} />

                                        <div className={styles.hint}>
                                            <Card>
                                                <img src={Flash3D} alt="bulb" className={styles.hintImage} />
                                                <div className={styles.hintContent}>
                                                    <div className={styles.hintTitle}>Vuoi andare più veloce?</div>
                                                    <div className={styles.hintDesc}>
                                                        Per rispondere usa i numeri <b>da 1 a 5</b> sulla tastiera e premi <b>Invio</b>.
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </>
                            }
                        </>
                    }
                    {result && radarData && !loadingResult && char &&
                        <div className={styles.result}>
                            <div className={typo.title} style={{ textAlign: 'center' }}>
                                {char.code} - {char.name}
                            </div>
                            <div className={styles.resultChart}>
                                <div className={styles.resultChartInner}>
                                    <div className={styles.resultDesc}>
                                        <div className={typo.headline} style={{ fontSize: '1.125rem' }}>
                                            Tratti della personalità
                                        </div>
                                        {char.description}
                                    </div>
                                    <RadarChart data={radarData} options={radarOptions} />
                                </div>
                                <div className={styles.resultData}>
                                    {Object.keys(result.explicit_axis_scores).map((key) => {
                                        let symbol = axisLabel.find(al => al.values.includes(key)).symbol
                                        let color = radarData[0].metadata.find(m => m.key === symbol).color
                                        return (
                                            <div className={styles.resultRow} key={key}>
                                                <div className={styles.resultColor} style={{ backgroundColor: color }} />
                                                <div className={styles.resultRowInner}>
                                                    <div style={{ fontWeight: 700 }}>{result.explicit_axis_scores[key]}</div>
                                                    <div style={{ fontWeight: 600, color: color }}>{key} {symbol}</div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className={styles.prosCons}>
                                <div className={styles.pros}>
                                    <img src={Pro3D} className={styles.proConsImage} alt="" />
                                    <div className={styles.prosTitle} style={{ paddingTop: '1rem' }}>
                                        Punto di forza
                                    </div>
                                    {char.pro}
                                </div>
                                <div className={styles.cons}>
                                    <img src={Cons3D} className={styles.proConsImage} alt="" />
                                    <div className={styles.consTitle} style={{ paddingTop: '1rem' }}>
                                        Punto di debolezza
                                    </div>
                                    {char.cons}
                                </div>
                            </div>
                            <div className={typo.subtitle} style={{ paddingTop: '2rem' }}>Interazione con i colleghi</div>
                            <div>{char.interactions.description}</div>
                            <div className={styles.prosCons}>
                                <div className={styles.pros} style={{ background: 'linear-gradient(180deg, rgba(96, 210, 101, 0.024) -31.4%, rgba(96, 210, 101, 0.12) 173.17%)' }}>
                                    <div className={styles.prosTitle} style={{ color: 'rgba(96, 210, 101, 0.8)' }}>
                                        Punti di forza nell'interazione
                                    </div>
                                    {char.interactions.pro}
                                </div>
                                <div className={styles.cons} style={{ background: 'linear-gradient(180deg, rgba(250, 102, 118, 0.024) -31.4%, rgba(250, 102, 118, 0.12) 173.17%)' }}>
                                    <div className={styles.consTitle} style={{ color: 'rgba(250, 102, 118, 0.8)' }}>
                                        Sfide nell'interazione
                                    </div>
                                    {char.interactions.cons}
                                </div>
                            </div>
                            <div className={typo.subtitle} style={{ paddingTop: '2rem' }}>Gestione dell'operatività lavorativa quotidiana</div>
                            <div>{char.work.description}</div>
                            <div className={styles.prosCons}>
                                <div className={styles.pros} style={{ background: 'linear-gradient(180deg, rgba(255, 204, 76, 0.024) -31.4%, rgba(255, 204, 76, 0.12) 173.17%)' }}>
                                    <div className={styles.prosTitle} style={{ color: 'rgba(255, 204, 76, 0.8)' }}>
                                        Punti di forza nell'operatività
                                    </div>
                                    {char.work.pro}
                                </div>
                                <div className={styles.cons} style={{ background: 'linear-gradient(180deg, rgba(250, 102, 118, 0.024) -31.4%, rgba(250, 102, 118, 0.12) 173.17%)' }}>
                                    <div className={styles.consTitle} style={{ color: 'rgba(250, 102, 118, 0.8)' }}>
                                        Sfide nell'operatività
                                    </div>
                                    {char.work.cons}
                                </div>
                            </div>
                        </div>
                    }
                </div>

                {(index !== -1 || result) &&
                    <BottomBar
                        backHidden={result || index === -1}
                        backDisabled={index === -1}
                        onBack={() => {
                            if (index > -1) {
                                setIndex(index - 1)
                            }
                        }}
                        continueLabel={continueLabel}
                        continueDisabled={continueDisabled}
                        onContinue={onContinue} />
                }
            </div>
            <AlertDialog open={alert.open} title={alert.title} text={alert.text} actions={alert.actions} onClose={() => {
                setAlert((prev) => {
                    prev.open = false
                    return { ...prev }
                })
            }} />
        </HeaderFooterLayout>
    )

}

export default Personalities
