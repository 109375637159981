import Daily from "@daily-co/daily-js";
import { DailyProvider } from "@daily-co/daily-react";
import { useContext, useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import ReactPlayer from "react-player";
import { useNavigate, useParams } from "react-router-dom";
import remarkGfm from "remark-gfm";
import api from "../api";
import { ReactComponent as ClockIcon } from "../assets/images/icons/ic-clock.svg";
import { ReactComponent as PlayIcon } from "../assets/images/illustrations/il-play-button.svg";
import { Each } from "../common/Each";
import MainContext from "../common/MainContext";
import TestCard from "../components/cards/TestCard";
import CourseBadge from "../components/CourseBadge";
import DailyRoom from "../components/daily/DailyRoom";
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout";
import MaterialButton from "../components/MaterialButton";
import Spinner from "../components/Spinner";
import TeachersBadge from "../components/TeachersBadge";
import TestCarousel from "../components/TestCarousel";
import typo from "../typography.module.css";
import { formatDateV2, formatTimeV2, getLocalDate } from "../utils";
import WebSocketClient from "../websocket";
import styles from "./Lesson.module.css";
import { useLocation } from "react-router-dom";

var interval = null

const Lesson = () => {
  const { t } = useTranslation();
  const context = useContext(MainContext)
  const navigate = useNavigate();
  const location = useLocation(); // Ottieni la posizione corrente
  const { roomName } = useParams();
  const [lesson, setLesson] = useState(null);
  const [teachers, setTeachers] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [call, setCall] = useState(null)
  const [minutesDiff, setMinutesDiff] = useState(null)
  const [roomError, setRoomError] = useState(null);
  const [websocket, setWebsocket] = useState(null)

  const [tests, setTests] = useState([])
  const [testId, setTestId] = useState(null)
  const [whiteboard, setWhiteboard] = useState({ show: false, data: [], sessionId: null })
  const [reconnecting, setReconnecting] = useState(false)

  useEffect(() => {
    return () => {
      if (interval) {
        clearInterval(interval);
        interval = null;
      }
      if (call) {
        call.leave();
        call.destroy();
      }
      if (websocket) {
        websocket.close();
      }
    };
  }, [call, websocket, location]);

  useEffect(() => {

    const getLessonTeachers = async (lessonId) => {
      try {
        const teachers = await api.get(`/lessons/${lessonId}/teachers`);

        if (teachers.length === 0) {
          throw new Error("No teachers found");
        }

        setTeachers(teachers);
      } catch (e) {
        console.error(e);
      }
    };

    const getLessonMaterial = async (lessonId) => {
      try {
        const materials = await api.get(`/lessons/${lessonId}/materials`);

        setMaterials(materials);
      } catch (e) {
        console.error(e);
      }
    };

    if (lesson) {
      getLessonTeachers(lesson.id)
      getLessonMaterial(lesson.id)
      getTestLive(lesson.id)

      interval = setInterval(() => {
        calculateMinutesDiff(lesson.starts_at)
      }, 1000)

      if (!lesson.ended_at && minutesDiff && minutesDiff > -30) {
        try {
          const call = Daily.createCallObject({ url: `https://startingfinance.daily.co/${lesson.room_name}` })
          call.join()
          setCall(call)
        }
        catch (e) {
          console.error(e)
        }
      }
    }
  }, [lesson]);

  useEffect(() => {

    const joinCall = async (call) => {
      try {
        await call.join()
        setCall(call)
      }
      catch (e) {
        console.error(e)
        setRoomError(e.errorMsg)
      }
    }

    if (lesson && minutesDiff) {
      if (!lesson.ended_at && minutesDiff > -30) {
        try {
          const call = Daily.createCallObject({ url: `https://startingfinance.daily.co/${lesson.room_name}` })
          joinCall(call);

          let socket = new WebSocketClient(`lessons/${roomName}`, onSocketMessageReceived)
          socket.init()
          setWebsocket(socket)

          document.addEventListener(`lessons/${roomName}-websocket-reconnecting`, onSocketReconnecting);
          document.addEventListener(`lessons/${roomName}-websocket-connected`, onSocketConnected);
        }
        catch (e) {
          console.error(e)
        }
      }
    }
  }, [minutesDiff, lesson])

  useEffect(() => {
    const getLesson = async (roomName) => {
      try {
        const lesson = await api.get(`/lessons/${roomName}`);

        setLesson(lesson);
      } catch (e) {
        if (e.code === 6) {
          navigate("/404");
        }
        console.error(e);
      }
    };

    if (roomName) {
      getLesson(roomName);
    }

  }, [roomName])

  const calculateMinutesDiff = (dateString) => {
    var now = new Date();
    var date = new Date(dateString);
    const offset = date.getTimezoneOffset()
    date = new Date(date.getTime() - (offset * 60 * 1000))

    const diff = now - date
    const mDiff = parseInt(diff / (1000 * 60))
    setMinutesDiff(mDiff)
  }

  const onSocketMessageReceived = (data) => {
    if (data.type === 'test_start') {
      let testId = data.testId
      setTestId(testId)
    }
    if (data.type === 'whiteboard_start') {
      setWhiteboard({ show: true, data: [], sessionId: data.session_id })
    }
    if (data.type === 'whiteboard_changed') {
      setWhiteboard({ show: true, data: data.data, sessionId: data.session_id })
    }
    if (data.type === 'whiteboard_stopped') {
      setWhiteboard({ show: false, data: [] })
    }
  }

  const getTestLive = async (lessonId) => {
    try {
      let tests = await api.get(`/lessons/${lessonId}/tests`)
      setTests(tests)
      let live = tests.find(t => getLocalDate(t.expires_at) > new Date())
      setTestId(live?.id ?? null)
    }
    catch (e) {
      console.error(e)
    }
  }

  const viewLesson = async (played, playedSeconds) => {
    try {
      const view = {
        played: played,
        played_seconds: playedSeconds
      }
      await api.post(`/lessons/${lesson.id}/view`, view)
    }
    catch (e) {
      console.error(e)
    }
  }

  const onVideoLessonProgress = async (data) => {
    localStorage.setItem(`lesson_${lesson.id}`, JSON.stringify(data))
  }

  const onSocketReconnecting = () => {
    setReconnecting(true)
  }

  const onSocketConnected = () => {
    setReconnecting(false)
  }

  return (
    <HeaderFooterLayout>
      <HelmetProvider>
        <Helmet>
          <title>{lesson ? lesson.name : 'Lezione'}</title>
        </Helmet>
      </HelmetProvider>
      <div className={styles.container}>
        {lesson && (
          <>
            <div
              className={styles.section}
              style={{ backgroundColor: "var(--background-color)" }}
            >
              <div className={styles.sectionInner} style={{ paddingBottom: 0 }}>
                {!lesson.video_url &&
                  <>
                    {minutesDiff !== null &&
                      <div className={styles.videoPlaceholder}>
                        {roomError &&
                          <div className={styles.noLesson}>
                            <div className={typo.headline} style={{ color: 'var(--background-color)' }}>
                              {roomError}
                            </div>
                          </div>
                        }
                        {!roomError &&
                          <>
                            {lesson.ended_at &&
                              <div className={styles.noLesson}>
                                <div className={typo.headline} style={{ color: 'var(--background-color)' }}>
                                  La lezione è terminata {formatDateV2(lesson.ended_at)} alle {formatTimeV2(lesson.ended_at)}. La registrazione sarà disponibile a breve.
                                </div>
                              </div>
                            }
                            {!lesson.ended_at &&
                              <>
                                {reconnecting &&
                                  <div className={styles.socketReconnecting}>
                                    <div style={{ width: '50px' }}>
                                      <Spinner color="black" />
                                    </div>
                                    <div className={styles.socketReconnectingContent}>
                                      <div className={styles.socketReconnectingTitle}>
                                        Riconnessione
                                      </div>
                                      <div className={styles.socketReconnectingDescription}>
                                        Alcune funzionalità come la lavagna virtuale o i test potrebbero non essere disponibili.
                                      </div>
                                    </div>

                                  </div>
                                }
                                {minutesDiff >= -30 && !call &&
                                  <div className={styles.noLesson}>
                                    <div className={typo.headline} style={{ color: 'var(--background-color)', display: 'flex', flexDirection: 'row', gap: '0.5rem', alignItems: 'center' }}>
                                      <Spinner />
                                      Caricando...
                                    </div>
                                  </div>
                                }
                                {minutesDiff >= -30 && call &&
                                  <DailyProvider callObject={call}>
                                    <DailyRoom
                                      lesson_id={lesson.id}
                                      whiteboard={whiteboard}
                                      onWhiteboardChange={(state) => {
                                        setWhiteboard(state)
                                      }} />
                                  </DailyProvider>
                                }
                                {minutesDiff !== null && minutesDiff < -30 &&
                                  <div className={styles.noLesson}>
                                    <div className={typo.headline} style={{ color: 'var(--background-color)' }}>
                                      La lezione è programmata per le ore {formatTimeV2(lesson.starts_at)} di {formatDateV2(lesson.starts_at)} e sarà accessibile mezz'ora prima dell'inizio.
                                    </div>
                                  </div>
                                }
                              </>
                            }
                          </>
                        }
                      </div>
                    }
                    {minutesDiff === null &&
                      <div className={styles.videoPlaceholder}>
                        <div className={styles.noLesson}>
                          <div className={typo.headline} style={{ color: 'var(--background-color)', display: 'flex', flexDirection: 'row', gap: '0.5rem', alignItems: 'center' }}>
                            <Spinner />
                            Caricando...
                          </div>
                        </div>
                      </div>
                    }
                  </>
                }
                {lesson.video_url &&
                  <div className={styles.videoPlaceholder}>
                    <div className={styles.playerWrapper}>
                      <ReactPlayer
                        url={lesson.video_url}
                        className={styles.player}
                        controls={true}
                        width="100%"
                        height="100%"
                        playIcon={<PlayIcon />}
                        light={lesson.thumbnail}
                        config={
                          {
                            youtube: {
                              playerVars: { showinfo: 1, modestbranding: 1, fs: 0, autoplay: 1 }
                            }
                          }
                        }
                        onProgress={onVideoLessonProgress}
                      />
                    </div>
                  </div>
                }
              </div>
            </div>
            <div
              className={styles.section}
              style={{ backgroundColor: "var(--background-color)", paddingTop: 0 }}
            >
              <div className={styles.sectionInner}>
                <div className={styles.lessonDetails}>
                  <div>
                    <TeachersBadge teachers={teachers} />
                  </div>
                  <div className={styles.lessonTime}>
                    {formatTimeV2(lesson.starts_at)} -{" "}
                    {formatTimeV2(lesson.ends_at)} <ClockIcon />
                  </div>
                </div>
              </div>

              <div className={styles.sectionInner}>
                <div className={styles.lessonHeader}>
                  <div className={styles.lessonTitle}>
                    <div className={typo.title}>
                      {lesson?.module.name} - {lesson?.name}
                    </div>
                    <CourseBadge type={lesson?.module.edition.course.type} />
                  </div>
                  {lesson?.description && (
                    <div className={styles.lessonDescription}>
                      <ReactMarkdown children={lesson.description} remarkPlugins={[remarkGfm]} />
                    </div>
                  )}
                </div>
              </div>

              {materials.length > 0 &&
                <div className={styles.sectionInner} style={{ flexDirection: "column", paddingTop: "1rem" }}>
                  <div className={typo.subheadline}>
                    Materiali didattici
                  </div>
                  <div className={styles.materials}>
                    <Each of={materials} render={(item) =>
                      <div className={styles.material}>
                        <MaterialButton material={item} />
                      </div>
                    } />
                  </div>
                </div>
              }

              {tests.length > 0 &&
                <div className={styles.sectionInner} style={{ flexDirection: "column", paddingTop: "1rem" }}>
                  <div className={typo.subheadline}>
                    Test
                  </div>
                  <div className={styles.tests}>
                    <div className={styles.testsTrack}>
                      <Each of={tests} render={(test) => {
                        return (
                          <div className={styles.testCardWrapper} onClick={async () => {
                            await new Promise((r) => {
                              setTestId(null)
                              r()
                            })

                            setTestId(test.id)
                          }}>
                            <TestCard test={test} style={{ pointerEvents: 'none' }} />
                          </div>
                        )
                      }} />
                    </div>
                  </div>
                </div>
              }

              {testId &&
                <div className={styles.sectionInner} style={{ flexDirection: "column", paddingTop: "1rem" }}>
                  <div className={styles.test}>
                    <TestCarousel testId={testId} onSubmitted={(passed) => {
                      websocket.sendMessage({
                        type: "test_submitted",
                        test_id: testId,
                        user: context.user
                      })
                    }} />
                  </div>
                </div>
              }
            </div>
          </>
        )}
      </div>
    </HeaderFooterLayout >
  );
};

export default Lesson;
