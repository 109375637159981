import { DailyAudio, DailyVideo, useParticipantProperty, useScreenShare } from "@daily-co/daily-react";
import { ReactComponent as CloseIcon } from "../../assets/images/icons/ic-close.svg";
import { useEffect, useRef, useState } from "react";
import { ReactComponent as MicOffIcon } from "../../assets/images/icons/ic-mic-off.svg";
import { ReactComponent as MicIcon } from "../../assets/images/icons/ic-mic.svg";
import { ReactComponent as ScreenShareIcon } from "../../assets/images/icons/ic-screenshare.svg";
import { ReactComponent as SettingsIcon } from "../../assets/images/icons/ic-settings.svg";

import styles from "./DailyParticipant.module.css";
import AudioSlider from "./AudioSlider";

const DailyParticipant = ({ session_id, isLocal = false, style, onClick = () => { }, muted }) => {

    const [participant, setParticipant] = useState(null)
    const participantProprerty = useParticipantProperty(session_id, ['audio', 'video', 'userData', 'tracks', 'user_name', 'owner'])
    const { screens } = useScreenShare()
    const [settingsMode, setSettingsMode] = useState(false)
    const [screenshare, setScreenshare] = useState(false)
    const [audioLevel, setAudioLevel] = useState(1)
    const [oldLevel, setOldLevel] = useState(1)

    const audioElementRef = useRef(null);

    useEffect(() => {
        if (participantProprerty) {
            let participant = {
                id: session_id,
                name: participantProprerty[4],
                owner: participantProprerty[5],
                audio: participantProprerty[0],
                video: participantProprerty[1],
                data: participantProprerty[2],
                tracks: participantProprerty[3],
                isLocal: isLocal
            }
            setParticipant(participant)
        }
    }, [participantProprerty])

    useEffect(() => {
        if (participant?.audio && !participant.isLocal) {
            const audioTrack = participant.tracks.audio.persistentTrack;
            if (audioTrack) {
                if(audioElementRef.current){
                    audioElementRef.current.pause()
                }
                // Crea il MediaStream e il nodo sorgente
                const mediaStream = new MediaStream([audioTrack]);
                // Collegamento opzionale a un elemento audio per la riproduzione
                const audioElement = new Audio();
                audioElementRef.current = audioElement;
                audioElement.srcObject = mediaStream;
                audioElement.volume = audioLevel ?? 1
                audioElement.play().catch((err) => {
                    console.error("Audio playback failed:", err);
                });
            }
        }
    }, [participant]);


    useEffect(() => {
        if (audioElementRef && audioElementRef.current) {
            audioElementRef.current.volume = audioLevel
            console.log(audioElementRef.current.volume)
        }

    }, [audioLevel])

    useEffect(() => {
        if (audioElementRef && audioElementRef.current) {
            if (muted) {
                setOldLevel(audioLevel)
                setAudioLevel(0)
            }
            else {
                setAudioLevel(audioLevel === 0 ? oldLevel : audioLevel)
            }
        }
    }, [muted])

    useEffect(() => {
        if (screens) {
            setScreenshare(!!screens.find(s => s.session_id === session_id))
        }
    }, [screens])

    return (
        <>
            {participant &&
                <div
                    className={styles.container}
                    style={{ ...style, backgroundColor: participant.data?.color ? participant.data.color : 'black' }}
                    onClick={() => {
                        onClick(session_id)
                    }}>
                    {(participant.video || screenshare) &&
                        <DailyVideo
                            type={screenshare ? 'screenVideo' : 'video'}
                            automirror={participant.isLocal}
                            sessionId={session_id}
                            style={{ display: 'flex', objectFit: 'cover', width: '100%', height: '100%' }} />
                    }
                    
                    {!settingsMode &&
                        <>
                            {participant.data &&
                                <div className={styles.name}>{participant.isLocal ? "Tu" : `${participant.owner ? participant.name : `Anonimo ${participant.data?.id}`}`}</div>
                            }
                            
                            <div className={`${styles.micBadge} ${!participant.audio ? styles.disabled : ''}`}>
                                {participant.audio ? <MicIcon /> : <MicOffIcon />}
                            </div>
                            
                            {participant && !participant.isLocal &&
                                <div className={styles.settingsBadge} onClick={(e) => {
                                    e.stopPropagation()
                                    setSettingsMode(!settingsMode)
                                }}>
                                    <SettingsIcon />
                                </div>
                            }

                            {participant && screens.find(s => s.session_id === session_id) &&
                                <div className={styles.screenShareBadge}>
                                    <ScreenShareIcon />
                                </div>
                            }
                        </>
                    }
                    {settingsMode &&
                        <div className={styles.overlay}>
                            <div className={styles.overlayHeader}>
                                <div className={styles.overlayName}>{participant.isLocal ? "Tu" : `${participant.owner ? participant.name : `Anonimo ${participant.data?.id}`}`}</div>
                                <div className={styles.settingsBadge}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        setSettingsMode(!settingsMode)
                                    }}>
                                    <CloseIcon />
                                </div>
                            </div>
                            
                            <AudioSlider level={audioLevel * 100} onChange={(value) => {
                                setAudioLevel(value / 100)
                            }} />
                        </div>
                    }
                </div>
            }
        </>
    )

}

export default DailyParticipant