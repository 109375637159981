import { useContext, useEffect, useRef, useState } from "react";
import styles from "./DailyChat.module.css"
import typo from "../../typography.module.css"
import { useAppMessage } from "@daily-co/daily-react";
import { Each } from "../../common/Each";
import TextareaAutosize from 'react-textarea-autosize'
import MainContext from "../../common/MainContext";
import { ReactComponent as SendIcon } from "../../assets/images/icons/ic-send.svg"
import { formatTimeV2, getLocaleISOString } from "../../utils";

const DailyChat = ({ open, unread }) => {

    const context = useContext(MainContext)
    const [message, setMessage] = useState({ sender: null, sender_type: 'user', created_at: null, body: '', type: null })
    const [messages, setMessages] = useState([]);
    const [sendDisabled, setSendDisabled] = useState(true)
    const messageInputRef = useRef(null)

    const onAppMessage = (ev) => {
        const newMessage = {
            ...ev.data,
            type: 'received'
        }
        setMessages((prev) => [newMessage, ...prev])
        if (!open) {
            unread.setState(unread.state + 1)
        }
        else {
            unread.setState(0)
        }
    }

    const sendAppMessage = useAppMessage({
        onAppMessage: onAppMessage
    });

    const handleOnChange = async (event) => {
        if (message.length > event.target.value.length) {
            setMessage((prev) => {
                prev.sender_type = 'user'
                prev.sender = context.user
                prev.created_at = getLocaleISOString()
                prev.body = event.target.value
                return { ...prev }
            })
            if (event.target.value.length > 0 && event.target.value.trim().length > 0) {
                setSendDisabled(false)
            }
            return
        }
        setMessage((prev) => {
            prev.sender_type = 'user'
            prev.sender = context.user
            prev.created_at = getLocaleISOString()
            prev.body = event.target.value

            return { ...prev }
        })
        if (event.target.value.length > 0 && event.target.value.trim().length > 0) {
            setSendDisabled(false)
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault()
            if (!sendDisabled) {
                sendMessage()
            }
            messageInputRef.current.focus()
        }
    };

    const sendMessage = async () => {
        sendAppMessage(message, '*')
        setMessages((prev) => [
            {
                sender: message.sender ?? context.user,
                sender_type: message.sender_type,
                created_at: message.created_at,
                body: message.body,
                type: 'sent'
            }, ...prev])
        setMessage({ sender: null, created_at: null, body: '' })
    }

    useEffect(() => {
        if (message) {
            if (message.body.trim().length === 0) {
                setSendDisabled(true)
            }
            else {
                setSendDisabled(false)
            }
        }
    }, [message])

    useEffect(() => {
        if (open && messageInputRef.current) {
            messageInputRef.current.focus()
        }
    }, [open])

    return (
        <div className={`${styles.container} ${open ? styles.open : ''}`}>
            <div className={typo.subtitle} style={{ color: 'white', paddingBottom: '.5rem', borderBottom: '1px solid rgba(var(--background-color-rgb), 12%)' }}>
                Chat
            </div>
            <div className={styles.messages}>
                <Each of={messages} render={(message) => {
                    return (
                        <div className={`${styles.message} ${message.type === 'sent' ? styles.sent : styles.received}`}>
                            <div className={`${styles.messageBody} ${message.type === 'sent' ? styles.sent : styles.received}`}>
                                {message.body}
                            </div>
                            <div className={styles.messageDate}>
                                {message.type === 'sent' &&
                                    formatTimeV2(message.created_at, null, 'it', false)
                                }
                                {message.type === 'received' &&
                                    <>
                                        {message.sender_type === 'teacher' &&
                                            <div style={{color: message.sender.color}}>{message.sender.name} {message.sender.surname} - {formatTimeV2(message.created_at, null, 'it', false)}</div>
                                        }

                                        {message.sender_type === 'user' &&
                                            <div style={{color: message.sender.color}}>Anonimo {message.sender.id} - {formatTimeV2(message.created_at, null, 'it', false)}</div>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    )
                }} />
            </div>
            <div className={styles.messageInputContainer}>
                <TextareaAutosize
                style={{height: 'unset'}}
                    ref={messageInputRef}
                    value={message.body}
                    minRows={1}
                    maxRows={message.body !== '' ? 5 : 1 }
                    type="text"
                    className={styles.messageInput}
                    placeholder="Scrivi un messaggio..."
                    onChange={handleOnChange}
                    onKeyDown={handleKeyDown} />
                <button className={styles.send} onClick={sendMessage} disabled={sendDisabled}>
                    <SendIcon />
                </button>
            </div>
        </div>
    )

}

export default DailyChat