import { useEffect, useState } from "react"
import styles from "./ArticleCard.module.css"
import Card from "./Card"
import { ArticleCardType } from "../../common/constants"
import Button from "../Button"
import { ReactComponent as ClockIcon } from "../../assets/images/icons/ic-clock.svg"
import Markdown from "react-markdown"

const ArticleCard = ({ article, type }) => {

    const [styleSize, setStyleSize] = useState(styles.large)

    useEffect(() => {
        if (type) {
            switch (type) {
                case ArticleCardType.Large:
                    setStyleSize(styles.large)
                    break
                case ArticleCardType.Medium:
                    setStyleSize(styles.medium)
                    break
                case ArticleCardType.Small:
                    setStyleSize(styles.small)
                    break
                default:
                // Nothing To Do 
            }
        }
    }, [type])

    return (
        <Card style={{ padding: '.5rem', width: '100%' }}>
            <div className={`${styles.container} ${styleSize}`}>
                <img src={article.thumbnail}
                    alt={`${article.name}-thumbnail`}
                    className={`${styles.thumbnail} ${styleSize}`} />
                <div className={`${styles.info} ${styleSize}`}>
                    <div className={`${styles.title} ${styleSize}`}>{article.name}</div>
                    <div className={`${styles.abstract} ${styleSize}`}>
                        <Markdown>
                            {article.abstract}
                        </Markdown>
                    </div>
                    <div className={styles.actionRow}>
                        <div className={styles.readingTime}>
                            <ClockIcon className={styles.clockIcon} />
                            {article.reading_time} {article.reading_time > 1 ? 'minuti' : 'minuto'}
                        </div>
                        <Button
                            appearance="text"
                            style={{ fontSize: type === ArticleCardType.Small ? '0.875rem' : '1rem' }}
                            onClick={() => { window.location.replace(article.url) }}
                        >
                            CONTINUA A LEGGERE
                        </Button>
                    </div>
                </div>
            </div>
        </Card>
    )
}

export default ArticleCard
